import { createSlice, current } from '@reduxjs/toolkit';


export const files = createSlice({
  name: 'files',
  initialState: {
    page: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 50, 100]
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
      state.page = 0
    }
  },
});

export const { setPage, setRowsPerPage } = files.actions;

export const getPage = state => state.files.page;
export const getRowsPerPage = state => state.files.rowsPerPage;
export const getRowsPerPageOptions = state => state.files.rowsPerPageOptions;

export default files.reducer;
