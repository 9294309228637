import React from "react";
import { useSelector } from "react-redux";
import ImagesList from '../../components/ImagesList/ImagesList';
import Grid from '@mui/material/Unstable_Grid2';
import { getImages } from "../Home/binariesReducer";

export default function ScrepedImages() {

  const allImages = useSelector(getImages);
  return (
    <Grid container spacing={2}>
      <Grid md={12}>
        <ImagesList images={allImages} search={true}/>
      </Grid>
    </Grid>
  );
};