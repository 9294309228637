import React from "react";
import { getImages } from "./binariesReducer";
import { useSelector } from "react-redux";
import ImagesList from '../../components/ImagesList/ImagesList';
import SearchBinaries from "../../components/SearchBinaries/SearchBinaries";
import Grid from '@mui/material/Unstable_Grid2';

export default function Home() {

  const allImages = useSelector(getImages);
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={7}>
        <SearchBinaries />
      </Grid>
      <Grid md={5} sx={{ display: { xs: 'none', md: 'block' }}}>
        <ImagesList images={allImages} search={true}/>
      </Grid>
    </Grid>
  );
};