import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';

import App from "./App";
import store from "./store";

import { setBinaries, setDb, setImages } from "./containers/Home/binariesReducer";
import { setDataLatestScrapedImages, setDataRepositories, setDataScrapedImagesByDate } from "./containers/Dashboard/dashboardReducer";

function fetchDataStats(file, dispatch, reducer) {
  const url = `${file}`;
  return fetch(url)
    .then((res) => res.json())
    .then((data) => {
      dispatch(reducer(data))
    });
}

fetchDataStats('stats_repositories.json', store.dispatch, setDataRepositories)
fetchDataStats('stats_scraped_images.json', store.dispatch, setDataScrapedImagesByDate)
fetchDataStats('stats_lastes_scraped_images.json', store.dispatch, setDataLatestScrapedImages)
fetchDataStats('./binaries.json', store.dispatch, setBinaries)
fetchDataStats('./images.json', store.dispatch, setImages)
// fetch("./binaries.json")
//   .then((res) => res.json())
//   .then((data) => {
//     store.dispatch(setBinaries(data))
//   });
// fetch("./images.json")
//   .then((res) => res.json())
//   .then((data) => {
//     store.dispatch(setImages(data))
//   });
//});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
