import { createSlice, current } from '@reduxjs/toolkit';

const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;

export const header = createSlice({
  name: 'header',
  initialState: {
    newImageModalOpen: false,
    isNewImageFormError: false,
    newImageCommand: "example: nginx:1.23.3-alpine-slim",
    imageNotificationMessage: "Image requested!",
    openImageNotification: false,
    imageNotificaitonSeverity: "success",
    contactUsModalOpen: false,
    isContactEmailError: false,
    isSubjectError: false,
    isTextError: false,
    email: "",
    subject: "",
    text: "",
    darkMode: true,
    aboutModalOpen: false
  },
  reducers: {
    toggleNewImageModalOpen: (state, action) => {
      state.newImageModalOpen = !state.newImageModalOpen
      state.contactUsModalOpen = false
      state.aboutModalOpen = false;
    },
    setNewImageCommand: (state, action) => {
      state.newImageCommand = action.payload
    },
    validateNewImageForm: (state, action) => {
      state.isNewImageFormError = state.newImageCommand === ""
    },
    setErrorNotificationNewImage: (state) => {
      state.imageNotificationMessage = "Oops! Something wrong has happened. Please try again later"
      state.imageNotificaitonSeverity = "error"
    },
    setSuccessNotificationNewImage: (state) => {
      state.imageNotificationMessage = "Image requested!"
      state.imageNotificaitonSeverity = "success"
    },
    toggleNewImageNotification: (state, action) => {
      state.openImageNotification = !state.openImageNotification
    },
    toggleContactUsModalOpen: (state, action) => {
      state.contactUsModalOpen = !state.contactUsModalOpen
      state.newImageModalOpen = false
      state.aboutModalOpen = false;
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setSubject: (state, action) => {
      state.subject = action.payload
    },
    setText: (state, action) => {
      state.text = action.payload
    },
    validateContactUsForm: (state, action) => {
      state.isTextError = state.text === ""
      state.isSubjectError = state.subject === ""      
      state.isContactEmailError = !state.email.match(validEmailRegex)
    },
    setSuccessNotificationContactUs: (state) => {
      state.imageNotificationMessage = "Request sent!"
      state.imageNotificaitonSeverity = "success"
    },
    toggleAboutModalOpen: (state, action) => {
      state.newImageModalOpen = false
      state.contactUsModalOpen = false
      state.aboutModalOpen = !state.aboutModalOpen;
    },
    toggleTheme: (state, action) => {
      state.darkMode = !state.darkMode;
    },
  },
});

export const { toggleNewImageModalOpen, toggleContactUsModalOpen, validateNewImageForm, setNewImageCommand,
  setErrorNotificationNewImage, setSuccessNotificationNewImage, toggleNewImageNotification,
  setEmail, setSubject, setText, validateContactUsForm, setSuccessNotificationContactUs,toggleAboutModalOpen, toggleTheme } = header.actions;

export const getNewImageModalOpen = state => state.header.newImageModalOpen;
export const isNewImageFormError = state => state.header.isNewImageFormError;
export const getNewImageCommand = state => state.header.newImageCommand;
export const getOpenImageNotification = state => state.header.openImageNotification;
export const getImageNotificaitonSeverity = state => state.header.imageNotificaitonSeverity;
export const getImageNotificaitonMessage = state => state.header.imageNotificationMessage;
export const getContactUsModalOpen = state => state.header.contactUsModalOpen;

export const isContactEmailError = state => state.header.isContactEmailError
export const isSubjectError = state => state.header.isSubjectError
export const isTextError = state => state.header.isTextError
export const isDarkMode = state => state.header.darkMode


export const getEmail = state => state.header.email
export const getSubject = state => state.header.subject
export const getText = state => state.header.text

export const  getAboutModalOpen = state => state.header.aboutModalOpen;

export default header.reducer;
