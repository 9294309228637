import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { Box,Button, Card, CardContent, CardHeader, Hidden } from "@mui/material";
import { getDataLatestScrapedImages, getDataRepositories, getDataScrapedImagesByDate, setDataLatestScrapedImages, setDataRepositories, setDataScrapedImagesByDate } from "./dashboardReducer";
import { withStyles } from '@mui/styles';
import { getColor } from "../Setting/settingsReducer";
import { useHistory } from 'react-router';

ChartJS.register(CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title, ArcElement, Tooltip, Legend);

// function fetchDataStats(file, dispatch, reducer) {
//     const url = `${file}`;
//     return fetch(url)
//         .then((res) => res.json())
//         .then((data) => {
//             dispatch(reducer(data))
//         });
// }

export const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: '',
        },
    },
};

const dataRepositoriesChartOpts = {
    ...chartOptions,
    plugins: {
        ...chartOptions.plugins,
        title: {
            ...chartOptions.plugins.title,
            text: 'Number of scraped images by repository',
        }
    }
}
const dataScrapedImagesByDateChartOpts = {
    ...chartOptions,
    plugins: {
        ...chartOptions.plugins,
        title: {
            ...chartOptions.plugins.title,
            text: 'Number of scraped images by date',
        }
    }
}

const styles = {
    card: {
        margin: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // height: '40vw'
    }
};


function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

function ChartDataRepositories(props) {
    const { classes, dataRepositories } = props;
    return (<Grid component={Card} xs className={classes.card}>
        <Card variant="outlined">
            <CardHeader
                title="Repositories"
            />
            <CardContent>
                <Bar options={dataRepositoriesChartOpts} data={dataRepositories} />
            </CardContent>
        </Card>
    </Grid>)
}

function ChartDataScrapedImagesByDate(props) {
    const { classes, dataScrapedImagesByDate } = props;
    return (<Grid component={Card} xs className={classes.card}>
        <Card variant="outlined">
            <CardHeader
                title="Scraped Images"
            />
            <CardContent>
                <Line options={dataScrapedImagesByDateChartOpts} data={dataScrapedImagesByDate} />
            </CardContent>
        </Card>
    </Grid>)
}

function TableLastestScrapedImages(props) {
    const { classes, dataLatestScrapedImages } = props;

    const getHeaders = () => {
        const headers = []
        for (let i = 0; i < dataLatestScrapedImages.headers.length; i++) {
            headers.push(<TableCell key={i}>{dataLatestScrapedImages.headers[i]}</TableCell>)
        }

        return (<>{headers}</>)
    }

    const getRows = (data) => {
        const rows = []
        for (let i = 0; i < data.length; i++) {
            rows.push(<TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {getCols(data[i])}
            </TableRow>)
        }

        return (<>{rows}</>)
    }
    const getCols = (row) => {
        const cols = []
        for (let i = 0; i < row.length; i++) {
            cols.push(<TableCell size={'small'} key={i} style={{
                whiteSpace: 'normal',
                wordWrap: 'break-word'
            }}>{row[i]}</TableCell>)
        }

        return (<>{cols}</>)
    }

    return (<Grid component={Card} xs className={classes.card}>
        <Card variant="outlined">
            <CardHeader
                title="Latest Scraped Images"
            />
            <CardContent>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {getHeaders(dataLatestScrapedImages)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getRows(dataLatestScrapedImages.data)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>

    </Grid>)
}

function SearchButtons(props) {
    const { inheritColor, classes, routerHistory } = props;
    return (<>
        <Grid xs className={classes.card}>
            <Button
                color={inheritColor} variant="outlined"
                // sx={{ display: { xs: 'none', md: 'block' } }}
                onClick={(event) => {
                    routerHistory.push("/home");
                }}
            >
                Click here to search your FILES!
            </Button>
        </Grid>
        <Grid xs className={classes.card}>
            <Button
                color={inheritColor} variant="outlined"
                // sx={{ display: { xs: 'none', md: 'block' } }}
                onClick={(event) => {
                    routerHistory.push("/scrapedimages");
                }}
            >
                Click here to search your IMAGES!
            </Button>
        </Grid></>)
}

function GridSmallScreen(props) {
    const { inheritColor, classes, dataRepositories, dataScrapedImagesByDate, dataLatestScrapedImages, routerHistory } = props;
    return (
        <Grid container alignItems="stretch" spacing={3}>
            <SearchButtons inheritColor={inheritColor} classes={classes} routerHistory={routerHistory} />
            <Box width="100%" />
            <ChartDataRepositories classes={classes} dataRepositories={dataRepositories} />
            <Box width="100%" />
            <ChartDataScrapedImagesByDate classes={classes} dataScrapedImagesByDate={dataScrapedImagesByDate} />
            <Box width="100%" />
            <TableLastestScrapedImages classes={classes} dataLatestScrapedImages={dataLatestScrapedImages} />
        </Grid>)
}

function GridBigScreen(props) {
    const { inheritColor, classes, dataRepositories, dataScrapedImagesByDate, dataLatestScrapedImages, routerHistory } = props;

    return (
        <Grid container alignItems="stretch" spacing={3}>
            <SearchButtons inheritColor={inheritColor} classes={classes} routerHistory={routerHistory} />
            <Box width="100%" />
            <ChartDataRepositories classes={classes} dataRepositories={dataRepositories} dataScrapedImagesByDate={dataScrapedImagesByDate} />
            <ChartDataScrapedImagesByDate classes={classes} dataRepositories={dataRepositories} dataScrapedImagesByDate={dataScrapedImagesByDate} />
            <Box width="100%" />
            <TableLastestScrapedImages classes={classes} dataLatestScrapedImages={dataLatestScrapedImages} />
        </Grid>)
}

function Dashboard(props) {
    const { classes } = props;
    const dispatch = useDispatch();
    const dataRepositories = useSelector(getDataRepositories);
    const dataScrapedImagesByDate = useSelector(getDataScrapedImagesByDate);
    const dataLatestScrapedImages = useSelector(getDataLatestScrapedImages);
    const routerHistory = useHistory();
    const color = useSelector(getColor);
    const inheritColor = color === "primary" ? "inherit" : color;
    // if (dataRepositories.labels.length == 0) {
    //     fetchDataStats('stats_repositories.json', dispatch, setDataRepositories)
    // }

    // if (dataScrapedImagesByDate.labels.length == 0) {
    //     fetchDataStats('stats_scraped_images.json', dispatch, setDataScrapedImagesByDate)
    // }

    // if (dataLatestScrapedImages.headers.length == 0) {
    //     fetchDataStats('stats_lastes_scraped_images.json', dispatch, setDataLatestScrapedImages)
    // }


    return (<>
        <Hidden smUp>
            <GridSmallScreen
                routerHistory={routerHistory}
                inheritColor={inheritColor}
                classes={classes}
                dataRepositories={dataRepositories}
                dataScrapedImagesByDate={dataScrapedImagesByDate}
                dataLatestScrapedImages={dataLatestScrapedImages}
            />
        </Hidden>
        <Hidden smDown>
            <GridBigScreen
                routerHistory={routerHistory}
                inheritColor={inheritColor}
                classes={classes}
                dataRepositories={dataRepositories}
                dataScrapedImagesByDate={dataScrapedImagesByDate}
                dataLatestScrapedImages={dataLatestScrapedImages}
            />
        </Hidden>
    </>

    );
};

export default withStyles(styles)(Dashboard);