import indigo from "@mui/material/colors/indigo";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import { createSlice } from '@reduxjs/toolkit';

const primaryColor = indigo;
const secondaryColor = green;

const themeConfig = {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: indigo,
    secondary: green,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    type: "dark",
    color: "secondary"
  }
};

// All the following keys are optional.
// We try our best to provide a great default value.
const defaultTheme = themeConfig;

export const settings = createSlice({
  name: 'settings',
  initialState: {
    theme: defaultTheme,
    darkMode: true,
    colorsSwaped: false,
    color: "secondary"
  },
  reducers: {
    toggleThemeMode: (state, action) => {
      if (action.payload) { //darknode set
        state.darkMode = true;
        state.theme = {
          ...themeConfig,
          palette: {
            ...themeConfig.palette,
            primary: state.theme.palette.primary,
            secondary: state.theme.palette.secondary,
            type: "dark",            
          }          
        };
        state.color="secondary";
      } else {
        state.darkMode = false;
        state.theme = {
          ...themeConfig,
          palette: {
            ...themeConfig.palette,
            primary: state.theme.palette.primary,
            secondary: state.theme.palette.secondary,
            
            type: "light"
          }
        };
        state.color="primary";
      }

      state.value = action.payload;
    },
    swapThemeColors: (state, action) => {
      // if (action.payload) { // colorsSwaped
      //   state.colorsSwaped = true;
      //   state.theme = {
      //       ...themeConfig,
      //       palette: {
      //         ...state.theme.palette,
      //         primary: secondaryColor,
      //         secondary: primaryColor
      //       }
      //     };
      // } else {
      //   state.colorsSwaped = false;
      //   state.theme = {
      //     ...themeConfig,
      //     palette: {
      //       ...state.theme.palette,
      //       primary: primaryColor,
      //       secondary: secondaryColor
      //     }
      //   };
      // }
    },
  },
});

export const { toggleThemeMode, swapThemeColors } = settings.actions;

export const isDarkMode = state => state.settings.darkMode;

export const isColorSwaped = state => state.settings.colorsSwaped;

export const getTheme = state => state.settings.theme;
export const getColor = state => state.settings.color;

export default settings.reducer;