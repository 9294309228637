import React, { Fragment, Component } from "react";
import { withStyles } from '@mui/styles';
import classNames from "classnames";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { isSideBarOpen } from "../components/Sidebar/sidebarReducer";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(3),
    marginTop: theme.spacing(7),
    overflowX: "hidden"
  },
  contentShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
});

function MainLayout(props) {
  const sideBarOpen = useSelector(isSideBarOpen);
  const { classes, children } = props;
  return (
    <Fragment>
      <div className={classes.root}>
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: sideBarOpen
          })}
        >
          {children}
        </main>
      </div>
      <Sidebar open={sideBarOpen} drawerWidth={drawerWidth} />
    </Fragment>
  );
}
export default withStyles(styles)(MainLayout);
