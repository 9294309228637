import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { createTheme } from '@mui/material/styles'

import Home from "./containers/Home/Home";
import ScrepedImages from "./containers/ScrepedImages/ScrepedImages";

import MainLayout from "./layouts/MainLayout";
import EmptyLayout from "./layouts/EmptyLayout";

import { getTheme } from "./containers/Setting/settingsReducer";

import ReactGA from "react-ga4";
import Dashboard from "./containers/Dashboard/Dashboard";
const TRACKING_ID = "G-LS3MVWMYM8"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const NotFound = () => {
  return <div>NotFound</div>;
};


const DashboardRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      )}
    />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

export default function App() {

  //const theTheme = useSelector(getTheme);
  const theme = createTheme(adaptV4Theme(useSelector(getTheme)));//createMuiTheme();

  const useStyles = makeStyles((theme) => {
    root: {
      // some CSS that accesses the theme
    }
  });


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ height: "100vh" }}>
          <Router>
            <Switch>
              <DashboardRoute path="/dashboard" component={Dashboard} />
              <DashboardRoute path="/home" component={Home} />
              <DashboardRoute path="/scrapedimages" component={ScrepedImages} />
              <DashboardRoute exact path="/" component={Dashboard} />
              <EmptyRoute component={NotFound} />
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};