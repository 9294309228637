import React from "react";
import classNames from "classnames";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useDispatch, useSelector } from "react-redux";
import { toggleAboutModalOpen, toggleContactUsModalOpen, toggleNewImageModalOpen } from "../Header/headerReducer";
import { withStyles } from '@mui/styles';
import { Drawer, Hidden, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useHistory } from 'react-router';
import { isSideBarOpen, toogleSideBar } from "./sidebarReducer";

const drawerWidth = 200;

const styles = theme => ({
  drawerPaper: {
    position: "fixed",
    top: theme.spacing(8),
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0)
    }
  }
});

const Sidebar = props => {
  const { classes } = props;
  const sideBarOpen = useSelector(isSideBarOpen);
  const dispatch = useDispatch();
  const routerHistory = useHistory();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !sideBarOpen && classes.drawerPaperClose
        )
      }}
      open={sideBarOpen}
    >
      <List component="nav" aria-label="main mailbox folders">
        {/* <Hidden smUp> */}
        <ListItemButton
            onClick={(event) => { routerHistory.push("/"); dispatch(toogleSideBar()) }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          <ListItemButton
            onClick={(event) => { routerHistory.push("/home"); dispatch(toogleSideBar()) }}
          >
            <ListItemIcon>
              <ManageSearchIcon />
            </ListItemIcon>
            <ListItemText primary="Scraped Files" />
          </ListItemButton>

          <ListItemButton
            onClick={(event) => { routerHistory.push("/scrapedimages"); dispatch(toogleSideBar()) }}
          >
            <ListItemIcon>
              <ImageSearchIcon />
            </ListItemIcon>
            <ListItemText primary="Scraped Images" />
          </ListItemButton>
        {/* </Hidden> */}
        <ListItemButton
          onClick={(event) => dispatch(toggleNewImageModalOpen({}))}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add Image" />
        </ListItemButton>
        <ListItemButton
          onClick={(event) => dispatch(toggleContactUsModalOpen({}))}
        >
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItemButton>

        <ListItemButton
          onClick={(event) => dispatch(toggleAboutModalOpen({}))}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(Sidebar);