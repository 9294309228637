import React from "react";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toggleImageInfoModalOpen, setImageFiles, getImagesArray, filterImage, clearImages, getAllRepoImages, setSelectedImage, getSelectedImage } from "../../containers/Home/binariesReducer";
import { Box,Autocomplete, Button, CardActions, CardContent, Chip, createFilterOptions, Divider, Hidden, TextField } from "@mui/material";
import ImageInfoModal from "../ImageInfoModal/ImageInfoModal";
import { setPage } from "../ImageInfoModal/FilesList/filesReducer";
import { getColor } from "../../containers/Setting/settingsReducer";
import axios from 'axios';
import ReactGA from "react-ga4";


function searchApi(what) {
    axios.post('/api/image', {
        what
    })
        .then(function (response) {
        })
        .catch(function (error) {
        });
    try {
        if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
            ReactGA.event({
                category: "Search",
                action: "search-image",
                //value: 99, // optional, must be a number
                //nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
            });
        }
    }
    catch (e) { }
}

const limit = 500
const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: limit,
});

function sortObj(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}

function ImageSearchWrapper(props) {
    const { selectedImage, dispatch, loading, searchLabelText, color, search, allImagesArr } = props;
    if (search) {
        return (<>
            <Autocomplete
                filterOptions={filterOptions}
                value={selectedImage}
                autoComplete
                autoHighlight
                disablePortal
                clearOnEscape
                blurOnSelect
                id="search-image"
                options={allImagesArr}
                sx={{ width: '100%' }}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        color={color}
                        label={searchLabelText}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />)}
                onChange={(event, value) => {
                    searchApi(value)
                    dispatch(setSelectedImage(value))
                    dispatch(clearImages())
                    dispatch(filterImage(value))
                }}
            />
            <p></p>
            <Divider />
        </>);
    }
    return (
        <></>
    )
}

function ImageBinaryPaths(props) {
    const { image } = props;
    if (!image.paths) {
        return (<></>);
    }
    const children = []
    for (let i = 0; i < image.paths.length; i++) {
        const path = image.paths[i];
        children.push(<ImageBinaryPath key={i} path={path}></ImageBinaryPath>)
    }
    return (<>
        <Typography>Searched Binaries:<br /></Typography>
        <ul style={{ paddingLeft: "20px", margin: "5px" }}>
            {children}
        </ul>
    </>)
}
function ImageBinaryPath(props) {
    const { path } = props;
    return (
        <li><Typography style={{ overflowWrap: "break-word" }}>{path}</Typography></li>
    )
}

export default function ImagesList(props) {
    const { images, search } = props;
    const selectedImage = useSelector(getSelectedImage);
    const allRepoImages = useSelector(getAllRepoImages);
    const allImagesArr = useSelector(getImagesArray);

    const dispatch = useDispatch();
    const color = useSelector(getColor);
    const searchLabelText = allImagesArr.length > 0 ? `Image to search` : "Loading...";
    const loading = allImagesArr.length == 0;

    function showImageDetail(payload) {
        if (!payload.details.files) {
            const url = `images/${payload.details.id}.json`;
            // console.log('Fetch', url)
            fetch(url)
                .then((res) => res.json())
                .then((data) => {
                    // console.log('Files fetched')
                    dispatch(setImageFiles({ name: payload.name, details: data }))
                });
        }
        dispatch(setPage(0));
        dispatch(toggleImageInfoModalOpen(payload))
    }
    if (Object.keys(images).length === 0) {
        return (<></>);
    }

    const sorted = sortObj(images);
    const accordion = [];
    for (let image in sorted) {
        // console.log("Image", image)
        accordion.push(<div key={image}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <span>{image} &nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp; <Chip label={allRepoImages[image].size} /></span>
                </AccordionSummary>
                <AccordionDetails>
                    <CardContent>
                        <ImageBinaryPaths image={images[image]}></ImageBinaryPaths>
                        <Typography >ID: {allRepoImages[image].id}</Typography>
                        <Typography >Repository: {allRepoImages[image].repository}</Typography>
                        <Hidden smDown><Typography >Tag: {allRepoImages[image].tag}</Typography></Hidden>
                        <Hidden smDown><Typography >Size: {allRepoImages[image].size}</Typography></Hidden>
                        <Typography >Created at: {allRepoImages[image].created}</Typography>
                        <Hidden smDown><Typography noWrap >Digest: {allRepoImages[image].digest}</Typography></Hidden>
                    </CardContent>
                    <CardActions>
                        <Box sx={{ flexGrow: 1 }}>
                            {image}:{allRepoImages[image].tag}
                        </Box>
                        <Button color={color} onClick={(event) => {
                            showImageDetail({ name: image, details: allRepoImages[image] })
                        }}>More Info</Button>
                    </CardActions>
                </AccordionDetails>
            </Accordion><br></br>
        </div>
        );
    }

    return (
        <>
            <ImageSearchWrapper
                selectedImage={selectedImage}
                searchLabelText={searchLabelText}
                dispatch={dispatch}
                color={color}
                loading={loading}
                search={search}
                allImagesArr={allImagesArr}></ImageSearchWrapper>
            <div
                style={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 250px)"
                }}
            >
                {accordion}
                <ImageInfoModal></ImageInfoModal>
            </div>

        </>
    );
};