import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import { withStyles } from '@mui/styles';
import { Alert, Button, FormControlLabel, Snackbar, Switch } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getImageNotificaitonMessage, getImageNotificaitonSeverity, getOpenImageNotification,
  isDarkMode, toggleAboutModalOpen, toggleContactUsModalOpen, toggleNewImageModalOpen,
  toggleNewImageNotification, toggleTheme
} from "./headerReducer";
import NewImageModal from "./modals/NewImageModal";
import ContactUsModal from "./modals/ContactUsModal";
import AboutModal from "./modals/AboutModal";
import Stack from '@mui/material/Stack';
import { getColor, toggleThemeMode } from "../../containers/Setting/settingsReducer";
import { toogleSideBar } from "../Sidebar/sidebarReducer";

const styles = theme => ({
  toolbarRoot: {
    paddingRight: 24
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  title: {
    flexGrow: 1
  }
});

const Header = props => {
  const dispatch = useDispatch();
  const darkMode = useSelector(isDarkMode);
  const themeLabel = darkMode ? "Dark" : "Light"
  const openImageNotification = useSelector(getOpenImageNotification);
  const imageNotificaitonSeverity = useSelector(getImageNotificaitonSeverity);
  const imageNotificationMessage = useSelector(getImageNotificaitonMessage);
  const color = useSelector(getColor);
  const inheritColor = color === "primary" ? "inherit" : color;
  function changeTheme(checked) {
    dispatch(toggleTheme({}));
    dispatch(toggleThemeMode(checked));
  }

  const { classes } = props;
  return (
    <AppBar position="fixed">
      <Toolbar disableGutters={true} classes={{ root: classes.toolbarRoot }}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={(event) => dispatch(toogleSideBar())}
          className={classes.menuButton}
          size="large">
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h6"
          color={inheritColor}
          className={classes.title}
        >
          Docker Scraper
        </Typography>      

        <Stack spacing={2} direction="row">
          <FormControlLabel color={color} 
            control={
              <Switch color={color} name="theme"
                checked={darkMode}
                onChange={(e, checked) => changeTheme(checked)}
              />
            }
            label={themeLabel}
          />
          <Button
            color={inheritColor}  variant="outlined"
            sx={{ display: { xs: 'none', md: 'block' } }}
            onClick={(event) => {
              dispatch(toggleNewImageModalOpen({}))
            }}>
            Add an Image
          </Button>

          <Button
            color={inheritColor}  variant="outlined"
            sx={{ display: { xs: 'none', md: 'block' } }}
            onClick={(event) => {
              dispatch(toggleContactUsModalOpen({}))
            }}>
            Contact Us
          </Button>

          <Button
            color={inheritColor}  variant="outlined"
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
            onClick={(event) => {
              dispatch(toggleAboutModalOpen({}))
            }}>
            About
          </Button>
        </Stack>
      </Toolbar>
      <NewImageModal></NewImageModal>
      <ContactUsModal></ContactUsModal>
      <AboutModal></AboutModal>
      <Snackbar open={openImageNotification} autoHideDuration={6000} onClose={() => { dispatch(toggleNewImageNotification({})) }}>
        <Alert onClose={() => { dispatch(toggleNewImageNotification({})) }} severity={imageNotificaitonSeverity} sx={{ width: '100%' }}>
          {imageNotificationMessage}
        </Alert>
      </Snackbar>
    </AppBar>

  );
};

export default withStyles(styles)(Header);
