import { createSlice, current } from '@reduxjs/toolkit';


export const sidebar = createSlice({
  name: 'sidebar',
  initialState: {
    open: false,
  },
  reducers: {
    toogleSideBar: (state, action) => {
      state.open = !state.open;
    }
  },
});

export const { toogleSideBar } = sidebar.actions;

export const isSideBarOpen = state => state.sidebar.open;

export default sidebar.reducer;
