import { CircularProgress, Hidden, ListItem, ListItemButton, ListItemText, TablePagination, Typography } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPage, getRowsPerPage, getRowsPerPageOptions, setPage, setRowsPerPage } from "./filesReducer";

export default function FilesList(props) {
    const { image } = props;
    const dispatch = useDispatch();
    const page = useSelector(getPage);
    const rowsPerPage = useSelector(getRowsPerPage);
    const rowsPerPageOptions = useSelector(getRowsPerPageOptions);

    if (!image.files) {
        return (<CircularProgress color="inherit" size={50} justifycontent="center" alignitems="center" />);
    }

    function handleChangePage(event, page) {
        dispatch(setPage(page));
    }

    function handleChangeRowsPerPage(event) {
        dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    }

    const list = [];
    const start = page * rowsPerPage
    const end = start + rowsPerPage > image.files.length ? image.files.length : start + rowsPerPage;
    for (let i = start; i < end; i++) {

        if (image.files[i] == "") { continue; }
        list.push(<ListItem key={i} component="div" disablePadding>
            <ListItemText primary={`${i + 1} - ${image.files[i]}`} />            
        </ListItem>)
    }
    const count = image.files.length;
    return (
        <>
            <div
                style={{
                    overflowY: "auto",
                    maxHeight: "300px"
                }}
            >
                <Typography >Scraped Files: {image.files.length}</Typography>
                {list}
                <Hidden smUp>
                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Hidden>
                <Hidden smDown>
                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Hidden>
            </div>
        </>
    );
};