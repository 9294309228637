import React from "react";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, CardActions, CardContent, Chip, Divider, ListItem, ListItemButton, ListItemText, Modal, Snackbar, TextField } from "@mui/material";
import {
    getContactUsModalOpen,
    getEmail,
    getImageNotificaitonMessage,
    getImageNotificaitonSeverity, getNewImageCommand, getNewImageModalOpen,
    getOpenImageNotification, getSubject, getText, isContactEmailError, isNewImageFormError,
    isSubjectError,
    isTextError,
    setEmail,
    setErrorNotificationNewImage,
    setNewImageCommand, setSubject, setSuccessNotificationContactUs, setSuccessNotificationNewImage, setText, toggleContactUsModalOpen, toggleNewImageNotification,
    validateContactUsForm,
    validateNewImageForm
} from "../headerReducer";
import axios from 'axios';
import { getColor } from "../../../containers/Setting/settingsReducer";
import ReactGA from "react-ga4";

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

export default function ContactUsModal(props) {
    const open = useSelector(getContactUsModalOpen);
    const dispatch = useDispatch();
    const color = useSelector(getColor);
    const inheritColor = color === "primary" ? "inherit" : color;

    const errorEmail = useSelector(isContactEmailError);
    const errorSubject = useSelector(isSubjectError);
    const errorText = useSelector(isTextError);
    const email = useSelector(getEmail);
    const subject = useSelector(getSubject);
    const text = useSelector(getText);

    const sendContactUsApi = () => {
        axios.post('/api/contact', {
            email,
            subject,
            text
        })
            .then(function (response) {
                dispatch(toggleContactUsModalOpen({}))
                dispatch(setSuccessNotificationContactUs({}))
                dispatch(toggleNewImageNotification({}))
            })
            .catch(function (error) {
                dispatch(setErrorNotificationNewImage({}))
                dispatch(toggleNewImageNotification({}))

            });
        try {
            if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
                ReactGA.event({
                    category: "Support",
                    action: "new-tiocket",
                    //value: 99, // optional, must be a number
                    //nonInteraction: true, // optional, true/false
                    transport: "xhr", // optional, beacon/xhr/image
                });
            }
        }
        catch (e) { }
    }
    return (
        <>
            <Modal
                open={open}
                onClose={(event) => {
                    dispatch(toggleContactUsModalOpen())
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}
                    noValidate
                    autoComplete="off"
                    component="form"
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Requested a new image to be scraper
                    </Typography>
                    <br></br>
                    <TextField
                        error={errorEmail}
                        fullWidth
                        label="Your Email"
                        inputProps={{
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off',
                            },
                        }}
                        defaultValue=""
                        onChange={(event) => {
                            dispatch(setEmail(event.target.value))
                            dispatch(validateContactUsForm({}))
                        }}
                    /><br></br><br></br>
                    <TextField
                        error={errorSubject}
                        inputProps={{
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off',
                            },
                        }}
                        fullWidth
                        label="Subject"
                        defaultValue=""
                        onChange={(event) => {
                            dispatch(setSubject(event.target.value))
                            dispatch(validateContactUsForm({}))
                        }}
                    /><br></br><br></br>
                    <TextField
                        error={errorText}
                        inputProps={{
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off',
                            },
                        }}
                        fullWidth
                        label="Text"
                        defaultValue=""
                        onChange={(event) => {
                            dispatch(setText(event.target.value))
                            dispatch(validateContactUsForm({}))
                        }}
                    />
                    <br></br><br></br>
                    {/* <Typography variant="h8" component="h6">
                        It may take up to 3 working days.
                    </Typography> */}
                    <hr></hr>
                    <Button color={color} onClick={(event) => {
                        dispatch(validateContactUsForm({}))
                        if (!errorEmail && !errorSubject && !errorText) {
                            sendContactUsApi();
                        }
                    }}>Send Request</Button>
                    <Button color="error" onClick={(event) => {
                        dispatch(toggleContactUsModalOpen({}))
                    }}>Abort</Button>
                </Box>

            </Modal>
        </>
    );
};