import React from "react";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { getImageInfoModalOpen, getSelectedImageInfoDialog, toggleImageInfoModalOpen, getImages, getAllRepoImages } from "../../containers/Home/binariesReducer";
import { Box,Button, Divider, Hidden, Modal } from "@mui/material";
import FilesList from "./FilesList/FilesList";
import { getColor } from "../../containers/Setting/settingsReducer";

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ImageInfoModal(props) {
    const open = useSelector(getImageInfoModalOpen);
    const selectedImageName = useSelector(getSelectedImageInfoDialog);
    const images = useSelector(getAllRepoImages);
    const imageDetails = images[selectedImageName];
    const color = useSelector(getColor);
    const dispatch = useDispatch();
    if (!selectedImageName) {
        return (<></>);
    }

    return (
        <>
            <Modal
                open={open}
                onClose={(event) => {
                    dispatch(toggleImageInfoModalOpen())
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Name: {selectedImageName}
                    </Typography>

                    <Typography >ID: {imageDetails.id}</Typography>
                    <Typography noWrap>Repository: {imageDetails.repository}</Typography>
                    <Typography >Tag: {imageDetails.tag}</Typography>
                    <Hidden smDown><Typography >Size: {imageDetails.size}</Typography></Hidden>
                    <Typography >Created at: {imageDetails.created}</Typography>
                    <Hidden smDown><Typography noWrap>Digest: {imageDetails.digest}</Typography></Hidden>
                    <br></br>
                    <Divider></Divider>
                    <br></br>
                    <FilesList image={imageDetails} name={selectedImageName}></FilesList>
                    <br></br>
                    <Divider></Divider>
                    <br></br>
                    <Button color={color} onClick={(event) => {
                        dispatch(toggleImageInfoModalOpen({}))
                    }}>Close</Button>
                </Box>

            </Modal>
        </>
    );
};