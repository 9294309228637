import { createSlice, current } from '@reduxjs/toolkit';

const commonProperties = function (o1, o2) {
  return Object.keys(o1).filter(function (itm) {
    return itm in o2
  });
}

export const binaries = createSlice({
  name: 'binaries',
  initialState: {
    db: {},
    binaries: [],
    selectedBinaries: [],
    allRepoImages: [],
    images: [],
    imagesArray: [],
    filteredImage: null,
    foundImages: {},
    searching: false,
    imageInfoModalOpen: false,
    selectedImageInfoDialog: "",
    selectedImage: ""
  },
  reducers: {
    setSelectedBinaries: (state, action) => {
      state.selectedBinaries = action.payload
    },
    setSelectedImage: (state, action) => {
      state.selectedImage = action.payload
    },
    setBinaries: (state, action) => {
      state.binaries = action.payload;
    },
    setImages: (state, action) => {
      state.images = action.payload;
      state.allRepoImages = action.payload;
      const imagesNames = Object.keys(state.images);
      imagesNames.forEach((imgName, index) => {
        state.imagesArray.push(imgName);
      });
    },
    setDb: (state, action) => {
      state.db = action.payload;
    },
    setBinary: (state, action) => {
      state.db[action.payload.binToSearch] = action.payload.data;
    },
    setSearchingStatus: (state, action) => {
      state.searching = action.payload
    },
    searchImages: (state, action) => {
      //console.log("--Search", action.payload)
      const binariesToSearch = action.payload.length;
      if (action.payload.length == 0) {
        state.foundImages = [];
        return;
      }
      state.foundImages = [];

      // Image intersection based on searched binaries

      // Search images
      let found = null;
      for (let i = 0; i < binariesToSearch; i++) {
        const binToSearch = action.payload[i];
        const currentState = current(state);
        //console.log("--Search for", binToSearch);
        let tmpFound = {};
        const binary = currentState.db[binToSearch];
        //console.log("--Found binary:", binary);
        for (const image in binary) {
          tmpFound[image] = binary[image];
        }
        //console.log("--tmpFound images:", tmpFound);
        if (found == null) {
          // This is the first binary of the list that we are searching,
          // so all the images containing that binary go to the found array
          found = { ...tmpFound };
        } else {
          // Extract the common images between the previous cycle and this one
          const commonImages = commonProperties(tmpFound, found);
          const foundTmp = {};
          // Add all intersected images to found images concatenating the binaries paths
          for (const key in commonImages) {
            foundTmp[commonImages[key]] = {
              ...tmpFound[commonImages[key]],
              paths: [...tmpFound[commonImages[key]].paths]
            };
            if (found[commonImages[key]]) {
              // Concat path of found binaries
              for (const pathTmp of found[commonImages[key]].paths) {
                foundTmp[commonImages[key]].paths.push(pathTmp);
              }
            }
          }
          found = foundTmp;
        }
      }
      //console.log("--Found reducer: ",found);
      state.foundImages = found;
      state.searching = false
    },
    toggleImageInfoModalOpen: (state, action) => {
      if (action.payload && action.payload.name) {
        state.selectedImageInfoDialog = action.payload.name
      }
      state.imageInfoModalOpen = !state.imageInfoModalOpen
    },
    setImageFiles: (state, action) => {
      if (state.allRepoImages[action.payload.name]) {
        state.allRepoImages[action.payload.name]['files'] = action.payload.details.files
      }
      if (state.images[action.payload.name]) {
        state.images[action.payload.name]['files'] = action.payload.details.files
      }
    },
    clearImages: (state, action) => {
      state.images = {};
    },
    filterImage: (state, action) => {
      if (!action.payload) {
        state.images = state.allRepoImages;
        return
      }
      const imagesNames = Object.keys(state.allRepoImages);
      for (const imgName of imagesNames)
        if (imgName === action.payload) {
          state.images[imgName] = state.allRepoImages[imgName];;
          return;
        }
    },
  },
});

export const { setSelectedImage, setSelectedBinaries, setDb, setBinaries, clearImages, searchImages, filterImage, setImages, setSearchingStatus, setBinary, toggleImageInfoModalOpen, setImageFiles } = binaries.actions;

export const getBinaries = state => state.binaries.binaries;
export const getSelectedBinaries = state => state.binaries.selectedBinaries;

export const getFoundImages = state => state.binaries.foundImages;
export const getImages = state => state.binaries.images;
export const getImagesArray = state => state.binaries.imagesArray;
export const getAllRepoImages = state => state.binaries.allRepoImages;

export const getFilteredImage = state => state.binaries.filteredImage;

export const getSearchingStatus = state => state.binaries.searching;
export const getDb = state => state.binaries.db;
export const getImageInfoModalOpen = state => state.binaries.imageInfoModalOpen;
export const getSelectedImageInfoDialog = state => state.binaries.selectedImageInfoDialog;
export const getSelectedImage = state => state.binaries.selectedImage;



export default binaries.reducer;
