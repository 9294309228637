import { configureStore } from '@reduxjs/toolkit';

import settings from "../containers/Setting/settingsReducer";
import binaries from "../containers/Home/binariesReducer"
import files from '../components/ImageInfoModal/FilesList/filesReducer';
import sidebar from '../components/Sidebar/sidebarReducer';
import header from '../components/Header/headerReducer';
import dashboard from '../containers/Dashboard/dashboardReducer';

export default configureStore({
  reducer: {
    header: header,
    settings: settings,
    binaries: binaries,
    files: files,
    sidebar: sidebar,
    dashboard: dashboard
  },
});
