import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { searchImages, getBinaries, getFoundImages, getSearchingStatus, setSearchingStatus, getDb, setBinary, getSelectedBinaries, setSelectedBinaries } from "../../containers/Home/binariesReducer";
import { useSelector, useDispatch } from "react-redux";
import ImagesList from '../ImagesList/ImagesList';
import { createFilterOptions, Typography } from "@mui/material";
import { getColor } from "../../containers/Setting/settingsReducer";
import axios from 'axios';
import ReactGA from "react-ga4";

function searchApi(what) {
  axios.post('/api/files', {
    what
  })
    .then(function (response) {
    })
    .catch(function (error) {
    });

  try {
    if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
      ReactGA.event({
        category: "Search",
        action: "search-file",
        //value: 99, // optional, must be a number
        //nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    }
  }
  catch (e) {  }
}

const limit = 500
const filterOptions = createFilterOptions({
  matchFrom: 'start',
  limit: limit,
});

function ImagesListWrapper(props) {
  const { images, searchingStatus } = props;
  if (searchingStatus) {
    return (<CircularProgress color="inherit" size={50} justifycontent="center" alignitems="center" />);
  }
  return (
    <ImagesList images={images} search={false}></ImagesList>
  )
}

export default function SearchBinaries() {
  const color = useSelector(getColor);
  const binaries = useSelector(getBinaries);
  const selectedBinaries = useSelector(getSelectedBinaries);
  const foundImages = useSelector(getFoundImages);
  const searchingStatus = useSelector(getSearchingStatus);
  const db = useSelector(getDb);

  const searchLabelText = binaries.length > 0 ? `Binaries to search (type to search)` : "Loading...";
  const loading = binaries.length == 0;

  const dispatch = useDispatch();

  let subheaderText = <></>;
  if (Object.keys(foundImages).length > 0) {
    //console.log(Object.keys(foundImages).length)
    subheaderText = <><Typography>Images containing <u>ALL</u> the searched binaries:</Typography><br></br></>;
  }

  function startSearchingImages(binaries, db) {
    if (binaries.length == 0) {
      dispatch(searchImages(binaries));
      return;
    }
    searchApi(binaries)
    dispatch(setSearchingStatus(true));

    for (let i = 0; i < binaries.length; i++) {
      const binToSearch = binaries[i];
      if (!db.hasOwnProperty(binToSearch)) {
        const url = encodeURI(`binaries/${binToSearch}.json`);
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            dispatch(setBinary({ binToSearch, data }))
            dispatch(searchImages(binaries));
          });
      } else {
        dispatch(searchImages(binaries));
      }
    }
  }

  return (
    <>
      <Autocomplete
        filterOptions={filterOptions}
        value={selectedBinaries}
        autoComplete
        autoHighlight
        disablePortal
        clearOnEscape
        blurOnSelect
        multiple
        id="combo-box-demo"
        options={binaries}
        sx={{ width: '100%' }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            color={color}
            label={searchLabelText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />)}
        onChange={(event, value) => {
          dispatch(setSelectedBinaries(value))
          startSearchingImages(value, db)
        }}
      />
      <p></p>
      <Divider />
      {subheaderText}
      <ImagesListWrapper images={foundImages} searchingStatus={searchingStatus}></ImagesListWrapper>
    </>
  );
};