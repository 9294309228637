import React from "react";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Card, CardContent, Modal } from "@mui/material";
import {
    getAboutModalOpen, toggleAboutModalOpen
} from "../headerReducer";
import { getColor } from "../../../containers/Setting/settingsReducer";

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

export default function AboutModal(props) {
    const open = useSelector(getAboutModalOpen);
    const dispatch = useDispatch();
    const color = useSelector(getColor);
    const inheritColor = color === "primary" ? "inherit" : color;

    return (
        <>
            <Modal
                open={open}
                onClose={(event) => {
                    dispatch(toggleAboutModalOpen())
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}
                    noValidate
                    autoComplete="off"
                    component="form"
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Docker Scraper
                    </Typography>
                    <br></br>
                    <Card>
                        <CardContent>
                            Are you locking for the best docker image that has all your requirements?<br></br>
                            <b>Docker Scraper</b> is the tool that you need!<br></br>
                            <b>Give it a try, it's free!!</b><br></br><br></br>
                            Version: 1.1.3<br></br>
                            Release Date: 2023.04.10<br></br>
                        </CardContent>
                    </Card>
                    <Button color={color} onClick={(event) => {
                        dispatch(toggleAboutModalOpen({}))
                    }}>Close</Button>
                </Box>

            </Modal>
        </>
    );
};