import { createSlice, current } from '@reduxjs/toolkit';


export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    dataRepositories: {
      labels: [],
      datasets: [
        {
          label: 'Repositories',
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            // 'rgba(255, 206, 86, 0.2)',
            // 'rgba(75, 192, 192, 0.2)',
            // 'rgba(153, 102, 255, 0.2)',
            // 'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            // 'rgba(255, 206, 86, 1)',
            // 'rgba(75, 192, 192, 1)',
            // 'rgba(153, 102, 255, 1)',
            // 'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    },
    dataScrapedImagesByDate: {
      "labels": [],
      "datasets": [
        {
          "label": "Dataset 1",
          "data": [],
          "borderColor": "rgb(255, 99, 132)",
          "backgroundColor": "rgba(255, 99, 132, 0.5)"
        }
      ]
    },
    dataLatestScrapedImages: {headers:[], data:[]}
  },
  reducers: {
    setDataRepositories: (state, action) => {
      state.dataRepositories = action.payload
    },
    setDataScrapedImagesByDate: (state, action) => {
      state.dataScrapedImagesByDate = action.payload
    },
    setDataLatestScrapedImages: (state, action) => {
      state.dataLatestScrapedImages = action.payload
    },
  },
});

export const { setDataRepositories, setDataScrapedImagesByDate, setDataLatestScrapedImages } = dashboard.actions;

export const getDataRepositories = state => state.dashboard.dataRepositories;
export const getDataScrapedImagesByDate = state => state.dashboard.dataScrapedImagesByDate;
export const getDataLatestScrapedImages = state => state.dashboard.dataLatestScrapedImages;

export default dashboard.reducer;
